<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">

	  			<!-- Titulo -->
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Reporte de ajuste de pagos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-radio-group v-model="estatus" row>
              <v-radio label="Todos"      :value="3"></v-radio>
              <v-radio label="Aceptados"  :value="1"></v-radio>
              <v-radio label="Pendientes" :value="0"></v-radio>
              <v-radio label="Rechazados" :value="2"></v-radio>
            </v-radio-group>

	  		    <v-spacer></v-spacer>
	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterAlumnos  , 'REPORTE_AJUSTE_PAGOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>

	  		  <v-card-text>
	  		  	<!-- Filtros -->
	  		  	<v-row justify="end">
	  		  		<v-spacer></v-spacer>
	  		  		<v-col cols="12" sm="6" md="4" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  single-line
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search2"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<!-- Tabla -->
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headersDos"
							    :items="filterReportes"
							    class="elevation-0"
							    :search="search2"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
			            id="grupos"
			            item-class="clase_row"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.tipo_reporte="{ item }">
							      <v-chip 
							      	v-for="(a, i) in tiposReporte" 
							      	:key="i" 
							      	v-if="a.id == item.tipo_reporte"
							      	small
							      >
							    		{{ a.texto }}
							    	</v-chip>
							    </template>

							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	small
							      	:color="item.estatus == 1 ? 'green': 'red'"
							      	dark
							      >{{ item.estatus == 1 ? 'Aceptada': 'Pendiente'  }}</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="red" 
							    		x-small
							    		dark
							    		@click="elimnarReporte(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-close</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>

	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      :max-width="currentWidth"
      persistent
    >
    	<v-card>
		    <v-card-title class="text-h6 font-weight-regular justify-space-between">
		      <span>{{ currentTitle }}</span>
		      <v-avatar
		        color="primary lighten-2"
		        class="subheading white--text"
		        size="24"
		        v-text="step"
		      ></v-avatar>
		    </v-card-title>

		    <v-window v-model="step">
		      <v-window-item :value="1">
		        <v-card>
			        <v-card-text>
			  		  	<!-- Filtros -->
			  		  	<v-row justify="end">
			  		  		<!-- Seleccionar el ciclo -->
			  		  		<v-col cols="12" sm="6" md="4" lg="3">
			  		  			<v-autocomplete
			  		  			  label="Ciclo"
			  		  			  id="id"
			  		  			  filled
			  		  			  dense
			  		  			  single-line
			  		  			  hide-details
			  		  			  v-model="ciclo"
			  		  			  :items="ciclos"
			  		  			  item-text="ciclo"
			  		  			  item-value="id_ciclo"
			  		  			  clearable
			  		  			></v-autocomplete>
			  		  		</v-col>
			  		  		<!-- Seleccionar el plantel -->
			  		  		<v-col cols="12" sm="6" md="4" lg="3">
			  		  			<v-autocomplete
			  		  			  label="Plantel"
			  		  			  id="id"
			  		  			  filled
			  		  			  dense
			  		  			  single-line
			  		  			  hide-details
			  		  			  v-model="plantel"
			  		  			  :items="planteles"
			  		  			  item-text="plantel"
			  		  			  item-value="id_plantel"
			  		  			  clearable
			  		  			></v-autocomplete>
			  		  		</v-col>
			  		  		<v-spacer></v-spacer>
			  		  		<v-col cols="12" sm="6" md="4" lg="5">
			  		  			<v-text-field
			  		  			  name="name"
			  		  			  label="Buscar"
			  		  			  id="id"
			  		  			  filled
			  		  			  dense
			  		  			  hide-details
			  		  			  single-line
			  		  			  append-icon="mdi-magnify"
			  		  			  v-model="search"
			  		  			></v-text-field>
			  		  		</v-col>
			  		  	</v-row>

			  		  	<!-- Tabla -->
			  		    <v-row>
			  		    	<v-col cols="12">
									  <v-data-table
									    :headers="headers"
									    :items="filterAlumnos"
									    class="elevation-0"
									    :search="search"
									    :mobile-breakpoint="100"
					            dense
					            id="grupos"
					            item-class="clase_row"
					            single-select
					            v-model="selected"
					            item-key="id_ingreso"
    									show-select
									  >
									  	<!-- Valores que ocupan botons, chips, iconos, etc -->
									    <template v-slot:item.baja="{ item }">
									      <v-chip 
									      	small
									      	:color="item.baja == 1 ? 'green': 'red'"
									      	dark
									      >{{ item.baja == 1 ? 'SI': 'NO'  }}</v-chip>
									    </template>

									    <template v-slot:no-data>
									      <v-btn
									        color="primary"
									        @click="initialize"
									        small
									      >
									        Actualizar
									      </v-btn>
									    </template>

									  </v-data-table>
			  		    	</v-col>
			  		    </v-row>
			  		  </v-card-text>
			      </v-card>
		      </v-window-item>

		      <v-window-item :value="2">
		        <v-card-text>
		          <v-radio-group v-model="tipo_reporte">
					      <v-radio
					        v-for="(tipo, i) in tiposReporte"
					        :key="i"
					        :label="`${tipo.texto}`"
					        :value="tipo.id"
					      ></v-radio>
					    </v-radio-group>
		        </v-card-text>
		      </v-window-item>

		      <v-window-item :value="3">
		        <v-card-text>
		        	<div class="black--text text-subtitle-1">
		        		<b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
		        		<br/>
					      <v-chip 
					      	v-for="(a, i) in tiposReporte" 
					      	:key="i" 
					      	v-if="a.id == tipo_reporte"
					      >
					    		<b>Tipo de reporte: </b> {{ a.texto }}
					    	</v-chip>
		        		<br/>
		        		<br/>
		        		<br/>
		        	</div>

		        	<v-row>

		        		<!-- ERROR EN MONTO PAGADO -->
		        		<v-col cols="12" md="6" lg="3" v-if="tipo_reporte == 2">
		        			<span>Ingresa la nueva cantidad pagada</span>
				        	<v-text-field
					          label="Ingresa el monto pagado"
					          type="number"
					          v-model="nuevo_monto_pago"
					          filled
					          dense
					        ></v-text-field>
		        		</v-col>

		        		<!-- ERROR EN DESCUENTO GRUPO -->
		        		<v-col cols="12" md="6" lg="3" v-if="tipo_reporte == 3">
		        			<span>Ingresa el nuevo descuento</span>
					        <v-col cols="12" class="pa-0">
						        <div class="text-subtitle-1"><strong>Curso Actual</strong></div>
						        <v-divider class="mb-4"></v-divider>
						        <p>{{ alumno.curso }} - Precio de lista: ${{ alumno.precio_nivel }}</p>
						        <div>
						          <div class="text-subtitle-1"><strong>Curso Nuevo</strong></div>
						          <v-divider class="mb-4"></v-divider>
						          <v-autocomplete
							          v-model="nuevo_descuento"
							          :items="precios"
							          item-text="curso"
							          item-value="iddescuentos_cursos"
							          filled
							          dense
						          ></v-autocomplete>
						        </div>
						      </v-col>
		        		</v-col>

		        		<!-- ERROR EN MÉTODO DE PAGO -->
		        		<v-col cols="12" md="6" lg="3" v-if="tipo_reporte == 4">
		        			<span>Selecciona el nuevo metódo de pago</span>
				        	<v-select
									  :items="tipos_pago"
									  v-model="nuevo_tipo_pago"
									  label="Forma de pago"
									  filled
									  dense
					          single-line
									  item-text="tipo"
									  item-value="id"
									></v-select>
		        		</v-col>

		        		<!-- ERROR EN FECHA DE PAGO -->
		        		<v-col cols="12" md="6" lg="3" v-if="tipo_reporte == 5">
		        			<span>Selecciona la fecha de pago</span>
			        		<v-text-field
					          label="Fecha de pago"
					          type="date"
					          v-model="nueva_fecha_pago"
					          filled
					          dense
					        ></v-text-field>
		        		</v-col>
		        	</v-row>

		          <v-data-table
						    :headers="headersDos"
						    :items="filterAlumnos"
						    class="elevation-0"
						    :search="search"
						    :mobile-breakpoint="100"
		            dense
		            id="grupos"
		            item-class="clase_row"
		            single-select
		            v-model="selected"
		            item-key="id_ingreso"
								show-select
								v-if="tipo_reporte != 3"
						  >
						    <!-- Valores que ocupan botons, chips, iconos, etc -->
						    <template v-slot:item.baja="{ item }">
						      <v-chip 
						      	small
						      	:color="item.baja == 1 ? 'green': 'red'"
						      	dark
						      >{{ item.baja == 1 ? 'SI': 'NO'  }}</v-chip>
						    </template>

						    <template v-slot:no-data>
						      <v-btn
						        color="primary"
						        @click="initialize"
						        small
						      >
						        Actualizar
						      </v-btn>
						    </template>

						  </v-data-table>
		        </v-card-text>
		      </v-window-item>
		    </v-window>

		    <v-divider></v-divider>

		    <v-card-actions>
		    	<v-btn
		    		v-if="step === 1"
		        text
		        @click="dialog = false"
		      >
		        Cancelar
		      </v-btn>
		      <v-btn
		        :disabled="step === 1"
		        text
		        @click="step--"
		      >
		        Back
		      </v-btn>
		      <v-spacer></v-spacer>
		      <v-btn
		     		v-if="step < 3"
		        :disabled="step === 3"
		        color="primary"
		        depressed
		        @click="step++"
		      >
		        Next
		      </v-btn>

		      <v-btn
		      	v-if="step == 3"
		        color="primary"
		        depressed
		        @click="confirmarReporte()"
		      >
		        Confirmar reporte
		      </v-btn>
		    </v-card-actions>
		  </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">¿Confirmas que deseas eliminar defiminitvamente este reporte?</v-card-title>
        <v-card-actions>
          <v-btn 
          	color="red" 
          	dark
          	small
          	rounded
          	@click="closeDelete"
          >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
          	color="primary" 
          	dark
          	small
          	rounded
          	@click="deleteItemConfirm"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <VerAjustePago        v-if="vermovimiento.estatus"    :movimiento="movimiento"  :vermovimiento="vermovimiento"     @initialize="initialize"/>
    <AjusteFecha          v-if="verajustefecha.estatus"   :movimiento="movimiento"  :verajustefecha="verajustefecha"   @initialize="initialize"/>
    <AjusteMetodoPago     v-if="verajustemetodo.estatus"  :movimiento="movimiento"  :verajustemetodo="verajustemetodo" @initialize="initialize"/>
    <AjusteMontoPago      v-if="verajustemonto.estatus"   :movimiento="movimiento"  :verajustemonto="verajustemonto"   @initialize="initialize"/>
    <AjusteDescuento      v-if="verajustedesc.estatus"    :movimiento="movimiento"  :verajustedesc="verajustedesc"     @initialize="initialize"/>
    <AjusteBajaAlumno     v-if="verajustebaja.estatus"    :movimiento="movimiento"  :verajustebaja="verajustebaja"     @initialize="initialize"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';

  import VerAjustePago      from '@/views/operaciones/ajustepagos/VerAjustePago.vue';
  import AjusteFecha        from '@/views/operaciones/ajustepagos/AjusteFecha.vue';
  import AjusteMetodoPago   from '@/views/operaciones/ajustepagos/AjusteMetodoPago.vue';
  import AjusteMontoPago    from '@/views/operaciones/ajustepagos/AjusteMontoPago.vue';
  import AjusteDescuento    from '@/views/operaciones/ajustepagos/AjusteDescuento.vue';
  import AjusteBajaAlumno   from '@/views/operaciones/ajustepagos/AjusteBajaAlumno.vue';

  import validarErrores     from '@/mixins/validarErrores'
  import funcionesExcel     from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      VerAjustePago,
      AjusteFecha,
      AjusteMetodoPago,
      AjusteMontoPago,
      AjusteDescuento,
      AjusteBajaAlumno
    },

    data: () => ({
    	tabs: 0, 
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      dialogBajaAlumno:false,
      editedIndex: -1,

      selected:[], 

      // Filtros: 
			plantel: null,
			ciclo: null,

      // tabla
			search:'',
			search2:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      alumnos:[],
      headersDos: [
        { text: 'id'            , value: 'idingresos_reporte'  },
        { text: 'Matricula'     , value: 'matricula'           },
        { text: 'Alumno'        , value: 'alumno'              },
        { text: 'Grupo'         , value: 'grupo'               },
        // { text: 'Monto pagado'  , value: 'monto_pagado'        },
        // { text: 'Forma pago'    , value: 'forma_pago'          },
        { text: 'Solicitó'      , value: 'nombre_completo'     },
        { text: 'Motivo'        , value: 'tipo_reporte'        },
        { text: 'Fecha'         , value: 'fecha_reporte'       },
        { text: 'Estatus'       , value: 'estatus'             },
        { text: 'Actions'       , value: 'actions'             },
      ],

      headers: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Matricula'     , value: 'matricula'       },
        { text: 'Alumno'        , value: 'alumno'          },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Plantel'       , value: 'plantel'         },
        { text: 'Monto pagado'  , value: 'monto_pagado'    },
        { text: 'Fecha'         , value: 'fecha_pago'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
        { text: 'Baja'          , value: 'baja'            },
        { text: 'Registró'      , value: 'nombre_completo' },
      ],

      ciclos:[],
			planteles:[],
			inscribirAlumno: false,
			inscribir: { estatus: false },
			menu:[{text:'Alumno',icono:'mdi-account'},{text:'Tutor',icono:'mdi-account'},{text:'Pagos',icono:'mdi-bitcoin'},{text:'Beca',icono:'mdi-percent-outline'}],
			inscripciones:[],
			movimientos:[],
			step: 1,

			tiposReporte:[
				{ id: 1, texto: 'Pago duplicado'},
				{ id: 2, texto: 'Error en monto pagado'},
				{ id: 3, texto: 'Error en costo de curso'},
				{ id: 4, texto: 'Error en método de pago'},
				{ id: 5, texto: 'Cambio de fecha'},
				{ id: 6, texto: 'Baja de alumno en el grupo'},
			],
			tipo_reporte:null,
			reportes:[],
			tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      nuevo_tipo_pago: 0,
      nueva_fecha_pago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nuevo_monto_pago:0,
      nuevo_descuento:0,
      editedItem:{},

      vermovimiento:{
      	estatus: false,
      },
      verajustefecha:{
      	estatus: false,
      },
      verajustemetodo:{
      	estatus: false,
      },
      verajustemonto:{
      	estatus: false,
      },
      movimiento:null,

      verajustedesc:{
      	estatus: false,
      },

      verajustebaja:{
      	estatus: false,
      },

      actualizarAdeudo:false,
	    actualizarAdeudoVista:false,
	    id_alumno: 0,
	    alumno: [],
	    precios: [],
	    precio: "",
	    grupo:[],
	    dialogActualizar: false,
	    precioSeleccionado: [],
	    idCursoAsociado: null,
	    headers4: [
	      { text: "Curso", value: "curso" },
	      { text: "Costo", value: "precio_nivel" },
	    ],

	    estatus: 3,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo reporte' : 'Editar reporte'
      },

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selececciona el movimiento'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },

      currentWidth () {
        switch (this.step) {
          case 1: return 1200
          case 2: return 450
          default: return 1200
        }
      },

      filterAlumnos( ){

      	const alumnos = this.movimientos

      	if( this.plantel ){
      		return alumnos.filter( el=> { return el.id_plantel == this.plantel })
      	}

      	if( this.step == 3 ){
      		return this.selected
      	}
      	
      	return alumnos
      },

      filterReportes( ){

      	if( this.estatus == 3 ){ return this.reportes }

      	return this.reportes.filter( el => el.estatus == this.estatus )

      }
    },

    watch: {
      dialog (val) { val || this.close() },

      dialogDelete (val) { val || this.closeDelete() },

      tabs( val ) { console.log( val ) },

      ciclo( val ) { if( val ){ this.getMovimientos() } },

      selected( val ) { if( val ){ this.getCostosAlumno() } }

    },

    async created () {
      await this.getCiclos()
      await this.getPlanteles()
      await this.initialize()
      await this.getPrecios( )
    },

    methods: {
      initialize () {
      	this.cargar        = true
				this.reportes      = []
        
        return this.$http.get('reporte.ajustepagos/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.reportes   = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getMovimientos () {
      	if( !this.ciclo ){ return this.validarErrorDirecto('Favor de seleccionar un ciclo para poder continuar') }
      	this.cargar        = true
        this.alumnos       = []
        this.inscripciones = []
				this.movimientos   = []
        
        return this.$http.get('ingresos.ciclo/' + this.ciclo).then(response=>{
        	this.movimientos   = response.data.movimientos
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      elimnarReporte (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        return this.$http.delete('reporte.eliminar/' + this.editedItem.idingresos_reporte ).then(response=>{
        	this.cargar      = false
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      closeDelete () {
        this.dialogDelete     = false
        this.dialogBajaAlumno = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
	      this.movimiento = item
      	switch( item.tipo_reporte ){
      		case 1: this.vermovimiento.estatus   = true; break; 
      	  case 2: this.verajustemonto.estatus  = true; break;
      	  case 3: this.verajustedesc.estatus   = true; break;
      		case 4: this.verajustemetodo.estatus = true; break; 
      		case 5: this.verajustefecha.estatus  = true; break; 
      		case 6: this.verajustebaja.estatus   = true; break; 
      	}
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      confirmarReporte( ){
      	let payload = {}
      	payload     = this.selected[0]
      	payload.tipo_reporte     = this.tipo_reporte
      	payload.id_solicita      = this.getdatosUsuario.iderp
      	payload.nuevo_monto_pago = this.tipo_reporte == 2 ? this.nuevo_monto_pago : 0
      	payload.nuevo_descuento  = this.tipo_reporte == 3 ? this.nuevo_descuento  : null
      	payload.nuevo_tipo_pago  = this.tipo_reporte == 4 ? this.nuevo_tipo_pago  : null
      	payload.nueva_fecha_pago = this.tipo_reporte == 5 ? this.nueva_fecha_pago : null
      	payload.baja_alumno      = this.tipo_reporte == 6 ? 1                     : null
      	
      	this.cargar = true
      	this.$http.post('reporte.ajustepagos', payload ).then(response=>{
      		this.selected = []
      		this.step     = 1
      		this.dialog   = false
      		this.initialize()
        	this.validarSuccess( response.data.message )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      getPrecios() {
      	this.$http.get("all.precios").then((response) => {
        	this.precios = response.data;
      	}).catch((error) => {
        	this.validarError(error);
      	}).finally(() => {
        	this.cargar = false;
      	});
    	},

    	getCostosAlumno() {

    		if( !this.selected.length ){ return false }

	      this.$http.get("precios.alumno/" + this.selected[0].id_alumno).then((response) => {
          this.alumno = response.data;
          this.precioSeleccionado.id           = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).iddescuentos_cursos;
          this.precioSeleccionado.precio_lista = this.precios.find((precio) => precio.id_curso == this.alumno.id_curso).precio_lista;
          this.idCursoAsociado                 = this.alumno.id_curso;
          this.precio                          = this.alumno.precio_lista;
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
	    },


	    obtenerIdCurso() {
	      if (this.nuevo_descuento) {
	        // Si hay un precio seleccionado, busca el id_curso correspondiente en la lista de precios
	        const precioEncontrado = this.precios.find(
	          (precio) => precio.iddescuentos_cursos === this.nuevo_descuento
	        );
	        if (precioEncontrado) {
	          // Si se encontró el precio, actualiza el idCursoAsociado con el id_curso correspondiente
	          this.idCursoAsociado = precioEncontrado.id_curso;
	        }
	      } else {
	        // Si no hay un precio seleccionado, establece idCursoAsociado como null o un valor predeterminado
	        this.idCursoAsociado = null; // O algún otro valor predeterminado
	      }
	    },
    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .adeudo_alumno{
  	background-color: #FF3030 !important;
  	color: white;
  }
</style>