<template>
	<v-dialog
    v-model="verajustemonto.estatus"
    persistent
    width="500"
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Monto pagado</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-row>
				  <v-col cols="12" md="6">
				  	<v-text-field
		          label="Monto pagado"
		          type="number"
		          v-model="movimiento.nuevo_monto_pago"
		          filled
		          dense
		          hide-details
		        ></v-text-field>
		  		</v-col>
		  	</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title>
				<span class="text-body-1"><b>Pago Oficial</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text  v-if="alumno">
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-row>
				      <v-col cols="12" v-if="alumno">
				        <b>Alumno:             </b> {{ alumno.alumno }}              <br/>
				        <b>Matricula:          </b> {{ alumno.matricula }}           <br/>
				        <span v-if="grupo">
				        	<b>Grupo: </b> {{ grupo.grupoSiguiente.grupo }} <br/>
				        </span>
				      </v-col>

							<v-col cols="12" md="8">
								<v-select
								  :items="tipos_pago"
								  v-model="movimiento.id_forma_pago"
								  label="Forma de pago"
								  filled
								  dense
				          single-line
								  item-text="tipo"
								  item-value="id"
				          hide-details
				          readonly
								></v-select>

								<v-list class="transparent" dense>
						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              Precio inicial:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ precio_inicial }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="descuento">
						        <v-list-item-title align="end" class="pr-2">
				              Descuento:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ descuento }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider v-if="descuento"></v-divider>


				          <v-list-item class="pa-0" v-if="pago_anterior">
				            <v-list-item-title align="end" class="pr-2">
				              Pago anterior:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				              <b>$ {{ pago_anterior }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="pago_anterior"></v-divider>

				          <v-list-item class="pa-0" v-if="saldoFavor">
				            <v-list-item-title align="end" class="pr-2">
				              Saldo a favor:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
				              <b>$ {{ saldoFavor }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="saldoFavor"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>Subtotal:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ parseFloat(saldoFavor) > parseFloat(total_a_pagar) ? 0 :  (parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>IVA:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ parseFloat(saldoFactura) }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total a pagar:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{  parseFloat(saldoFavor) > parseFloat(total_a_pagar) ? 0 :  (parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) + parseFloat(saldoFactura) }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total pagado:</b>
				            </v-list-item-title>

			              <v-list-item-subtitle class="text-h6 black--text">
			              	<v-text-field
				                dense
				                type="number"
				                v-model="total_pagado"
				              ></v-text-field>
				            </v-list-item-subtitle>
						        
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="adeudo">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Faltante:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
						          <b>$ {{ adeudo }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>

				          <v-list-item class="pa-0" v-if="nuevoSaldoFavor">
				            <v-list-item-title align="end" class="pr-2">
				              Nuevo saldo a favor:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
				              <b>$ {{ nuevoSaldoFavor }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>

						    </v-list>

						    <div>
						    	<v-btn color="green" dark small class="mr-2 text-capitalize" v-if=" ( ((parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) + parseFloat(saldoFactura)) - total_pagado ) <= 0 ">Liquidado</v-btn>
						    </div>
							</v-col>
						</v-row>
		  		</v-col>
		  	</v-row>
			</v-card-text>

	    <v-card-actions>
	      <v-btn
	        @click="cerrar()"
	        rounded
	        color="red"
	        dark
	        class="text-capitalize"
	      >
	        Cerrar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="procesarPago()"
	        rounded
	        color="green"
	        dark
	        class="text-capitalize"
	      >
	        Actualizar datos
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'verajustemonto',
			'movimiento'
		],

    data: () => ({
    	alumno: null,
    	grupo:  null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      pagos:[],
      pagosOficiales:[],
      // tabla
      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago:null,
			sucursal:null,
			total_pagado:0,

			label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      isPracticaja: false,
      isTransferencia:false,
      isSeven: false,
      isOXXO: false,
      isWeb:false,
      isTarjeta:false,
      isSantander: false,
      isDirectoBancoBBVA: false,
      isDirectoBANREGIO: false,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      saldoFavor: 0,
      tieneSaldoFavor: false,

      fechapago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura:0,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],

      dialogEliminar: false,
			ingreso: null
    }),

    async created () {
    	this.total_pagado = this.movimiento.monto_pagado
    	await this.getAlumno()
    	await this.getPagoGrupo()
    	await this.getPrecioPagar()
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },

      adeudo( ){
        const restante = ((parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat( this.saldoFavor )) + parseFloat(this.saldoFactura)) - parseFloat(this.total_pagado)
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },

      nuevoSaldoFavor( ){
        const sobrante = parseFloat(this.total_pagado) - ((parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat( this.saldoFavor )) + parseFloat(this.saldoFactura))
        if( sobrante > 0 )
          return sobrante.toFixed(2)
        else
          return 0
      }
    },

    watch: {
      total_a_pagar( ){
        if( this.saldoFactura ){
          this.saldoFactura = (this.total_a_pagar * .16).toFixed(2)
        }else{ this.saldoFactura = 0 }
      },

      tipo_pago( val ){
        this.total_pagado = 0
      }
    },


    methods: {
      getAlumno( ) {
      	this.cargar = true
        return this.$http.get('inscripciones.alumnos/' + this.movimiento.id_alumno ).then(response=>{
        	this.cargar = false
        	this.alumno = response.data.alumno
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async iniciarValidaciones( ){
        this.cargar = true
        this.getPagoGrupo()
        this.cargar = false
      },

      getPagoGrupo( ) {
      	this.cargar = true
      	this.grupo  = null

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio,
      		id_grupo : this.movimiento.id_grupo
      	}
        return this.$http.post('inscripcion.grupo.alumno', payload).then(response=>{
        	this.cargar = false
        	this.grupo = response.data
        }).catch( error =>{
          this.tipoAlumno = error.body.tipoAlumno
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      cerrar( ){
      	this.$emit("initialize")
      	this.verajustemonto.estatus = false
      },

      getPrecioPagar( ) {
        let grupo = this.grupo
      	this.cargar = true
      	
      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		id_grupo:  this.movimiento.id_grupo,
      		escuela:   this.alumno.unidad_negocio
      	}

        return this.$http.post('pago.grupo', payload).then(response=>{

        	this.precio_inicial         = response.data.precio_inicial
					this.descuento              = response.data.descuento
					this.total_a_pagar          = response.data.total_a_pagar
          this.pago_anterior          = response.data.pago_anterior - this.movimiento.monto_pagado
          this.saldoFavor             = response.data.saldoFavor 
          this.tieneSaldoFavor        = response.data.tieneSaldoFavor 

        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async procesarPago( ){
      	this.cargar = true

      	const payload = {
      		// grupos alumnos
      		monto_pagado_total  : parseFloat( this.pago_anterior )  + parseFloat( this.total_pagado ),
      		monto_saldo_favor   : parseFloat(this.saldoFavor),
			    pago_completado_sn  : this.adeudo <= 0 ? 1 : 0,

      		// Ingresos
			    id_ingreso          : this.movimiento.id_ingreso,
			    monto_pagado        : this.total_pagado,

			    // alumnos_grupos_especiales_carga
          pagado              : parseFloat( this.pago_anterior )  + parseFloat( this.total_pagado ),
          adeudo              : parseFloat(this.adeudo),

          // EXTRAS
			    id_alumno           : this.alumno.id_alumno,
          id_grupo            : this.grupo.grupoSiguiente.id_grupo,
          nuevoSaldoFavor     : parseFloat( this.nuevoSaldoFavor ),

			    // REPORTE DE INGRESO
			    idingresos_reporte  : this.movimiento.idingresos_reporte,
			    id_autoriza         : this.getdatosUsuario.iderp
				}

				console.log( payload  )
				this.$http.post('reporte.montopago', payload ).then( response => {
          this.validarSuccess( response.data.message )
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },
    },
  }
</script>