<template>
	<v-dialog
    v-model="verajustedesc.estatus"
    persistent
    width="500"
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Nuevo curso</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-row>
				  <v-col cols="12" md="6">
				  	<span v-if="precios">{{ precios.curso }} </span>
		  		</v-col>
		  	</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title>
				<span class="text-body-1"><b>Pago Oficial</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text  v-if="alumno">
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-row>
				      <v-col cols="12" v-if="alumno">
				        <b>Alumno:             </b> {{ alumno.alumno }}              <br/>
				        <b>Matricula:          </b> {{ alumno.matricula }}           <br/>
				        <span v-if="grupo">
				        	<b>Grupo: </b> {{ grupo.grupoSiguiente.grupo }} <br/>
				        </span>
				      </v-col>

							<v-col cols="12" md="8">
								<v-list class="transparent" dense>
						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Costo con curso actual:</b>
				            </v-list-item-title>

						        <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{ parseFloat(total_a_pagar) }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

				          <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Costo con nuevo curso:</b>
				            </v-list-item-title>

						        <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{ precios ? parseFloat(precios.precio_lista) : 'El curso no existe' }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total pagado:</b>
				            </v-list-item-title>

			              <v-list-item-subtitle class="text-h6 black--text">
			              	<b>$ {{ total_pagado }}</b> 
				            </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="adeudo">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Adeudo:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
						          <b>$ {{ precios ? adeudo : 'Sin curso' }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
						    </v-list>

						    <div>
						    	<v-btn color="green" dark small tile class="mr-2 text-capitalize" 
						    		v-if=" precios && adeudo <= 0 ">
						    		Liquidado
						    	</v-btn>
						    </div>
							</v-col>
						</v-row>
		  		</v-col>
		  	</v-row>
			</v-card-text>

	    <v-card-actions>
	      <v-btn
	        @click="cerrar()"
	        color="black"
	        dark
	        class="text-capitalize"
	        small
	        tile
	      >
	      	<v-icon small left>mdi-cancel</v-icon>
	        Cancelar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="procesarPago()"
	        color="green"
	        dark
	        class="text-capitalize"
	        small
	        tile
	      >
	      	<v-icon small left>mdi-content-save</v-icon>
	        Guardar
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'verajustedesc',
			'movimiento'
		],

    data: () => ({
    	alumno: null,
    	grupo:  null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      pagos:[],
      pagosOficiales:[],
      // tabla
      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago:null,
			sucursal:null,
			total_pagado:0,

			label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      isPracticaja: false,
      isTransferencia:false,
      isSeven: false,
      isOXXO: false,
      isWeb:false,
      isTarjeta:false,
      isSantander: false,
      isDirectoBancoBBVA: false,
      isDirectoBANREGIO: false,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      saldoFavor: 0,
      tieneSaldoFavor: false,

      fechapago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura:0,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],

      dialogEliminar: false,
			ingreso: null,
			nuevo_descuento:0,

			precios: null,

    }),

    async created () {
    	this.total_pagado = this.movimiento.monto_pagado

    	console.log( this.movimiento )
    	await this.getAlumno()
    	await this.getPagoGrupo()
    	await this.getPrecioPagar()
      await this.getPrecios( )
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },

      adeudo( ){
        const restante =  this.precios ? parseFloat(this.precios.precio_lista) - this.total_pagado : 0
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },

      nuevoSaldoFavor( ){
        const sobrante = this.total_pagado - ((this.total_a_pagar - this.pago_anterior) - this.saldoFavor ) + this.saldoFactura
        if( sobrante > 0 )
          return sobrante.toFixed(2)
        else
          return 0
      },

      computedTotalPago( ){
      	return this.total_a_pagar - this.pago_anterior - this.nuevo_descuento - this.saldoFavor + parseFloat(this.saldoFactura)
      }
    },

    watch: {
      total_a_pagar( ){
        if( this.saldoFactura ){
          this.saldoFactura = (this.total_a_pagar * .16).toFixed(2)
        }else{ this.saldoFactura = 0 }
      },

      tipo_pago( val ){
        this.total_pagado = 0
      }
    },


    methods: {
      getAlumno( ) {
      	this.cargar = true
        return this.$http.get('inscripciones.alumnos/' + this.movimiento.id_alumno ).then(response=>{
        	this.cargar = false
        	this.alumno = response.data.alumno
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async iniciarValidaciones( ){
        this.cargar = true
        this.getPagoGrupo()
        this.cargar = false
      },

      getPagoGrupo( ) {
      	this.cargar = true
      	this.grupo  = null

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio,
      		id_grupo : this.movimiento.id_grupo
      	}
        return this.$http.post('inscripcion.grupo.alumno', payload).then(response=>{
        	this.cargar = false
        	this.grupo = response.data
        }).catch( error =>{
          this.tipoAlumno = error.body.tipoAlumno
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      cerrar( ){
      	this.$emit("initialize")
      	this.verajustedesc.estatus = false
      },

      getPrecioPagar( ) {
        let grupo = this.grupo
      	this.cargar = true
      	
      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		id_grupo:  this.movimiento.id_grupo,
      		escuela:   this.alumno.unidad_negocio
      	}

        return this.$http.post('pago.grupo', payload).then(response=>{

        	this.precio_inicial         = parseFloat( response.data.precio_inicial )
					this.descuento              = parseFloat( response.data.descuento )
					this.total_a_pagar          = parseFloat( response.data.total_a_pagar )
          this.pago_anterior          = parseFloat( response.data.pago_anterior ) - parseFloat( this.movimiento.monto_pagado )
          this.saldoFavor             = parseFloat( response.data.saldoFavor ) 
          this.tieneSaldoFavor        = parseFloat( response.data.tieneSaldoFavor ) 
          this.nuevo_descuento        = parseFloat( response.data.descuento )

        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async procesarPago( ){
      	this.cargar = true

      	const payload = {
			    // alumnos_grupos_especiales_carga
          adeudo                                : parseFloat(this.adeudo),
          precio_grupo_con_descuento_x_alumno   : this.precios.precio_lista,

          // EXTRAS
			    id_alumno              : this.alumno.id_alumno,
          id_grupo               : this.grupo.grupoSiguiente.id_grupo,

          id_curso               : this.precios.id_curso,

			    // REPORTE DE INGRESO
			    idingresos_reporte     : this.movimiento.idingresos_reporte,
			    id_autoriza            : this.getdatosUsuario.iderp
				}


				this.$http.post('reporte.montodescuento', payload ).then( response => {
          this.validarSuccess( response.data.message )

          this.cerrar()
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },


      getPrecios() {
      	this.$http.get("all.precios").then((response) => {
        	this.precios = response.data.find( el => el.iddescuentos_cursos == this.movimiento.nuevo_descuento );
      		console.log( this.precios )
      	}).catch((error) => {
        	this.validarError(error);
      	}).finally(() => {
        	this.cargar = false;
      	});
    	},

    },
  }
</script>