<template>
	<v-dialog
    v-model="verajustefecha.estatus"
    persistent
    width="500"
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Cambio de fecha</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-row>
				  <v-col cols="12">
				  	<v-text-field
		          label="Fecha de pago"
		          type="date"
		          v-model="movimiento.nueva_fecha_pago"
		          filled
		          dense
		        ></v-text-field>
		  		</v-col>
		  	</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title>
				<span class="text-body-1"><b>Pago Oficial</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text  v-if="alumno">
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-row>
				      <v-col cols="12" v-if="alumno">
				        <b>Alumno:             </b> {{ alumno.alumno }}              <br/>
				        <b>Matricula:          </b> {{ alumno.matricula }}           <br/>
				        <span v-if="grupo">
				        	<b>Grupo: </b> {{ grupo.grupoSiguiente.grupo }} <br/>
				        </span>
				      </v-col>

							<v-col cols="12">
				        <v-btn 
				          color="orange"
				          dark
				          class="mb-3"
				          @click="getPrecioPagar()"
				        >Calcular costo</v-btn>
				 
								<v-select
								  :items="tipos_pago"
								  v-model="movimiento.id_forma_pago"
								  label="Forma de pago"
								  filled
								  dense
				          single-line
								  item-text="tipo"
								  item-value="id"
				          hide-details
								></v-select>

								<v-list class="transparent" dense>
						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              Precio inicial:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ precio_inicial }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="descuento">
						        <v-list-item-title align="end" class="pr-2">
				              Descuento:
				            </v-list-item-title>

						        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
						          <b>$ {{ descuento }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider v-if="descuento"></v-divider>


				          <v-list-item class="pa-0" v-if="pago_anterior">
				            <v-list-item-title align="end" class="pr-2">
				              Pago anterior:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				              <b>$ {{ pago_anterior }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="pago_anterior"></v-divider>

				          <v-list-item class="pa-0" v-if="saldoFavor">
				            <v-list-item-title align="end" class="pr-2">
				              Saldo a favor:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
				              <b>$ {{ saldoFavor }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="saldoFavor"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>Subtotal:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ parseFloat(saldoFavor) > parseFloat(total_a_pagar) ? 0 :  (parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

				          <v-list-item class="pa-0" v-if="solcitaFactura">
				            <v-list-item-title align="end" class="pr-2">
				              <b>IVA:</b>
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ parseFloat(saldoFactura) }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>
				          <v-divider v-if="solcitaFactura"></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total a pagar:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="text-h6 black--text">
						          <b>$ {{  parseFloat(saldoFavor) > parseFloat(total_a_pagar) ? 0 :  (parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) + parseFloat(saldoFactura) }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Total pagado:</b>
				            </v-list-item-title>

			              <v-list-item-subtitle class="text-h6 black--text">
				              <b>$ {{ parseFloat(total_pagado) }}</b>
				            </v-list-item-subtitle>
						        
						      </v-list-item>
				          <v-divider></v-divider>

						      <v-list-item class="pa-0" v-if="adeudo">
						        <v-list-item-title align="end" class="pr-2">
				              <b>Faltante:</b>
				            </v-list-item-title>
						        
				            <v-list-item-subtitle class="red--text font-weight-semibold text-h6 black--text">
						          <b>$ {{ adeudo }}</b>
						        </v-list-item-subtitle>
						      </v-list-item>

				          <v-list-item class="pa-0" v-if="nuevoSaldoFavor">
				            <v-list-item-title align="end" class="pr-2">
				              Nuevo saldo a favor:
				            </v-list-item-title>
				            
				            <v-list-item-subtitle class="green--text font-weight-semibold text-body-1">
				              <b>$ {{ nuevoSaldoFavor }}</b>
				            </v-list-item-subtitle>
				          </v-list-item>

						    </v-list>

				        <!-- SOLICITAR FACTURA -->
				        <div v-if=" ( ((parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) + parseFloat(saldoFactura)) - total_pagado ) <= 0 || solcitaFactura">
				          <v-checkbox 
				            label="Con Factura" 
				            v-model="solcitaFactura" 
				            :value="1"
				            small
				            dense
				            readonly
				          ></v-checkbox>
				        </div>

						    <div>
						    	<v-btn color="green" dark small class="mr-2 text-capitalize" v-if=" ( ((parseFloat(total_a_pagar) - parseFloat(pago_anterior) - parseFloat(saldoFavor)) + parseFloat(saldoFactura)) - total_pagado ) <= 0 ">Liquidado</v-btn>
						    	<v-btn color="green" dark small class="text-capitalize" v-if="beca">Beca del {{ beca.becaTotal }}% </v-btn>
						    </div>
						  	<!-- <canvas id="pdf_renderer_cambridge" style="max-width: 100%;"></canvas> -->
							</v-col>

							<v-col cols="12" md="6" v-if="tipo_pago && tipo_pago != 1" class="pa-0">
				        <v-card-text align="center" class="pa-0">
				          <div v-if="!file">
				            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
				              <div class="dropZone-info" @drag="onChange">
				                <span class="fa fa-cloud-upload dropZone-title"></span>
				                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
				                <div class="dropZone-upload-limit-info">
				                  <div>Extensión: png, jpg, jpeg, svg</div>
				                  <div>Tamaño máximo: 10 MB</div>
				                </div>
				              </div>
				              <input type="file" @change="onChange">
				            </div>
				          </div>

				          <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
				          <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>

				          <div class="font-weight-semibold text-body-1 text-center" v-if="isPracticaja"><strong>PRACTICAJA</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isTransferencia"><strong>TRANSFERENCIA {{ banco }}</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isSeven"><strong>7-SEVEN</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isOXXO"><strong>OXXO</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isWeb"><strong>PAGO DESDE WEB</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isTarjeta"><strong>TARJETA</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBancoBBVA"><strong>PAGO EN BANCO BBVA</strong></div>
				          <div class="font-weight-semibold text-body-1 text-center" v-if="isDirectoBANREGIO"><strong>PAGO EN BANCO BANREGIO</strong></div>
				        </v-card-text>

							</v-col>
						</v-row>
		  		</v-col>
		  	</v-row>
			</v-card-text>

	    <v-card-actions>
	      <v-btn
	        @click="verajustefecha.estatus = false"
	        rounded
	        color="red"
	        dark
	        class="text-capitalize"
	      >
	        Cancelar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="procesarPago()"
	        rounded
	        color="green"
	        dark
	        class="text-capitalize"
	      >
	        Actualizar datos
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'verajustefecha',
			'movimiento'
		],

    data: () => ({
    	alumno: null,
    	grupo:  null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      pagos:[],
      pagosOficiales:[],
      // tabla
      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago:null,
			sucursal:null,
			total_pagado:0,

			label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      isPracticaja: false,
      isTransferencia:false,
      isSeven: false,
      isOXXO: false,
      isWeb:false,
      isTarjeta:false,
      isSantander: false,
      isDirectoBancoBBVA: false,
      isDirectoBANREGIO: false,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      saldoFavor: 0,
      tieneSaldoFavor: false,

      fechapago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      pagosCursos:[],

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura:0,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],

      dialogEliminar: false,
			ingreso: null
    }),

    async created () {
    	this.total_pagado = this.movimiento.monto_pagado
    	await this.getAlumno()
    	await this.iniciarValidaciones()

    	// await this.getPrecioPagar()
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },

      adeudo( ){
        const restante = ((parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat( this.saldoFavor )) + parseFloat(this.saldoFactura)) - parseFloat(this.total_pagado)
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },

      nuevoSaldoFavor( ){
        const sobrante = parseFloat(this.total_pagado) - ((parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat( this.saldoFavor )) + parseFloat(this.saldoFactura))
        if( sobrante > 0 )
          return sobrante.toFixed(2)
        else
          return 0
      }
    },

    watch: {
      solcitaFactura ( val ){
        if( val ){
          this.saldoFactura = ((parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat( this.saldoFavor )) *.16).toFixed(2)

          let grupo = this.grupo

          if( this.grupo.length ){
            grupo = this.grupo[0]
          }

          if( grupo.factura ){
            this.saldoFactura = ( (parseFloat(this.precio_inicial) - parseFloat(this.descuento)) * .16).toFixed(2)
          }

        }else{ this.saldoFactura = 0 }

      },

      total_a_pagar( ){
        if( this.saldoFactura ){
          this.saldoFactura = (this.total_a_pagar * .16).toFixed(2)
        }else{ this.saldoFactura = 0 }
      },

    	// grupo ( ){
    	// 	if( this.grupo ){ this.getPrecioPagar( ) }
    	// },

      archivos( val ){
        this.total_pagado       = 0
        this.isPracticaja       = false
        this.isTransferencia    = false
        this.isSeven            = false
        this.isOXXO             = false
        this.isWeb              = false
        this.isTarjeta          = false
        this.isDirectoBancoBBVA = false
        this.isDirectoBANREGIO  = false
        if( !val ){ this.vistaPrevia = null } 
      },

      tipo_pago( val ){
        this.total_pagado = 0
      }
    },


    methods: {
      getAlumno( ) {
      	this.cargar = true
        return this.$http.get('inscripciones.alumnos/' + this.movimiento.id_alumno ).then(response=>{
        	this.cargar = false
        	this.alumno = response.data.alumno
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async iniciarValidaciones( ){
        this.cargar = true
        const hermanos = await this.validarHermano( ).then( response => response )

        if( hermanos && hermanos.hermanos.length ){ 
          this.hermanos    = hermanos.hermanos
          this.tipoAlumno  = hermanos.tipoAlumno
          this.grupos      = []

          for( const i in this.hermanos ){
            this.hermanos['tipoAlumno'] = this.tipoAlumno
          }

          for( const i in hermanos.hermanos){
            if( typeof(hermanos.hermanos[i]) == 'object' ){
             
              this.grupos.push( hermanos.hermanos[i] )

            }        
          }

          if( this.tipoAlumno == 1 ){ this.getGruposDisponibles( ) }
          
          this.cargar = false
          
          return
        }

        this.getGruposAlumno()
        
        this.cargar = false
      },

      validarHermano( ){
        return new Promise(( resolve, reject ) => {
          const payload = {
            id_alumno: this.alumno.id_alumno,
            escuela  : this.alumno.unidad_negocio
          }

          this.$http.post('validar.hermanos', payload).then(response=>{
            resolve( response.data )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      getGruposAlumno( ) {
      	this.cargar = true
      	this.grupo  = null

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio,
      		id_grupo : this.movimiento.id_grupo
      	}
        return this.$http.post('inscripcion.grupo.alumno', payload).then(response=>{
        	this.cargar = false
        	this.grupo = response.data

          if( this.grupo && ![2,4].includes(this.grupo.tipoAlumno)){
            this.getGruposDisponibles( )
          }

        }).catch( error =>{
          this.tipoAlumno = error.body.tipoAlumno
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      cerrar( ){
      	this.$emit("initialize")
      	this.verajustefecha.estatus = false
      },

      getPrecioPagar( ) {
        let grupo = this.grupo

        if( this.grupo.length ){
          grupo = this.grupo[0]
        }

      	this.cargar = true
      	this.grupos = []
      	const payload = {
      		id_alumno:        this.alumno.id_alumno,
      		id_empleado:      null,
      		tipoAlumno:       grupo.tipoAlumno ? grupo.tipoAlumno : this.grupo.tipoAlumno,
      		empleado_activo:  0,
      		id_curso:         grupo.grupoSiguiente ? grupo.grupoSiguiente.id_curso : grupo.id_curso,
      		grupoSiguiente:   grupo.grupoSiguiente ? grupo.grupoSiguiente          : grupo,
          hermanos:         this.grupo.length ? this.grupo : false ,
          fechapago:        this.movimiento.nueva_fecha_pago,
          factura:          grupo.factura,
          unidad_negocio:   this.alumno.unidad_negocio
      	}

        return this.$http.post('inscripciones.calcular.precio', payload).then(response=>{

        	this.precio_inicial         = response.data.precio_inicial
					this.descuento              = response.data.descuento
					this.total_a_pagar          = response.data.total_a_pagar
					this.beca                   = response.data.beca 
          this.pago_anterior          = response.data.pago_anterior - this.movimiento.monto_pagado
          this.saldoFavor             = response.data.saldoFavor 
          this.tieneSaldoFavor        = response.data.tieneSaldoFavor 
          this.pagosCursos            = response.data.pagosCursos
          this.descuentoRecomendados  = response.data.descuentoRecomendados
          this.descRecomienda         = response.data.descRecomienda
          this.descuentosEspeciales   = response.data.descuentosEspeciales

        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      realizarPago( data ){
        return new Promise((resolve,reject)=>{
          this.$http.post('registrar.grupo.alumno', data ).then( response => {
            // Convertir el monto en texto
            const monto = convertir.NumerosALetras(data.monto_pagado_total).toUpperCase()

            const ruta = `https://pruebas.schoolofenglishadmin.com/reporteshelper/repoteDePagoParcial/${response.data.ingreso.id}/${data.monto_pagado_total}`

            window.open( ruta )
            resolve('Inscrito correctamente')
          }).catch( error =>{
            this.reject( error )
          }).finally( () => { this.cargar = false })
        })
      },

      async procesarPago( ){
      	this.cargar = true

      	const payload = {
          adeudo                     : parseFloat(this.adeudo),
			    descuento                  : this.descuento,
			    id_ingreso                 : this.movimiento.id_ingreso,
			    id_alumno                  : this.alumno.id_alumno,
          id_grupo                   : this.grupo.grupoSiguiente.id_grupo,
			    id_tipo_inscripcion        : this.total_pagado >= this.total_a_pagar ? 2 : 3,
			    id_usuario_ultimo_cambio   : this.getdatosUsuario.iderp,
			    monto_descuento_grupo      : parseFloat( this.descuento ) + parseFloat(this.saldoFavor),
			    monto_pagado_total         : parseFloat( this.nuevoSaldoFavor ) > 0 ? ( parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) ) : (parseFloat(this.saldoFavor) + parseFloat(this.total_pagado)) ,
			    monto_saldo_favor          : parseFloat(this.saldoFavor),
          nuevoSaldoFavor            : parseFloat( this.nuevoSaldoFavor ),
			    pago_completado_sn         : this.total_pagado >= (parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior)) ? 1 : 0,
			    precio_curso               : this.precio_inicial,
			    precio_grupo_con_descuento : this.total_a_pagar,
			    precio_grupo_sin_descuento : this.precio_inicial,
          saldoFavor                 : this.saldoFavor,
          tieneSaldoFavor            : this.tieneSaldoFavor,
          total_pagado_alumno        : (parseFloat(this.precio_inicial) + parseFloat(this.saldoFactura)),
				}

				this.$http.post('registrar.grupo.alumno', payload ).then( response => {
          // Convertir el monto en texto
          this.pagoRealizao = response.data.ingreso.id
          this.dialogConfirmaCorreo = true

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },

      

      grabarImagen( ){
      	return new Promise((resolve,reject)=>{
	        let formData = new FormData();
	        //se crea el objeto y se le agrega como un apendice el archivo
	        formData.append("file", this.file);

	        this.$http.post("grabar.comprobante", formData).then( response => {
	          resolve( response.data ) 
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
        })
      }
    },
  }
</script>