<template>
	<v-dialog
    v-model="verajustebaja.estatus"
    persistent
    width="500"
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Baja de alumno</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title>
				<span class="text-body-1"><b>Grupo Actual</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text  v-if="alumno">
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-row>
				      <v-col cols="12" v-if="alumno">
				        <b>Alumno:             </b> {{ alumno.alumno }}              <br/>
				        <b>Matricula:          </b> {{ alumno.matricula }}           <br/>
				        <span v-if="grupo">
				        	<b>Grupo: </b> {{ grupo.grupoSiguiente.grupo }} <br/>
				        </span>
				        <b>Pago anterior: </b> {{ pago_anterior }}
				      </v-col>
						</v-row>
		  		</v-col>
		  	</v-row>
			</v-card-text>

	    <v-card-actions>
	      <v-btn
	        @click="cerrar()"
	        rounded
	        color="red"
	        dark
	        class="text-capitalize"
	      >
	        Cerrar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="procesarPago()"
	        rounded
	        color="green"
	        dark
	        class="text-capitalize"
	      >
	        Dar de baja
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'verajustebaja',
			'movimiento'
		],

    data: () => ({
    	alumno: null,
    	grupo:  null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      dialogEliminar: false,
			ingreso: null,
			pago_anterior:0
    }),

    async created () {
    	await this.getAlumno()
    	await this.getPagoGrupo()
    	await this.getPrecioPagar()
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    },

    methods: {
      getAlumno( ) {
      	this.cargar = true
        return this.$http.get('inscripciones.alumnos/' + this.movimiento.id_alumno ).then(response=>{
        	this.cargar = false
        	this.alumno = response.data.alumno
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async iniciarValidaciones( ){
        this.cargar = true
        this.getPagoGrupo()
        this.cargar = false
      },

      getPagoGrupo( ) {
      	this.cargar = true
      	this.grupo  = null

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio,
      		id_grupo : this.movimiento.id_grupo
      	}
        return this.$http.post('inscripcion.grupo.alumno', payload).then(response=>{
        	this.cargar = false
        	this.grupo = response.data
        }).catch( error =>{
          this.tipoAlumno = error.body.tipoAlumno
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar( ) {
        let grupo = this.grupo
      	this.cargar = true
      	
      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		id_grupo:  this.movimiento.id_grupo,
      		escuela:   this.alumno.unidad_negocio
      	}

        return this.$http.post('pago.grupo', payload).then(response=>{
          this.pago_anterior          = response.data.pago_anterior
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      cerrar( ){
      	this.$emit("initialize")
      	this.verajustebaja.estatus = false
      },

      async procesarPago( ){
      	this.cargar = true

      	const payload = {
      		// Ingresos
			    id_ingreso          : this.movimiento.id_ingreso,
			    id_alumno           : this.alumno.id_alumno,
          id_grupo            : this.grupo.grupoSiguiente.id_grupo,
			    idingresos_reporte  : this.movimiento.idingresos_reporte,
			    id_autoriza         : this.getdatosUsuario.iderp,
			    pago_anterior       : this.pago_anterior,
          id_plantel          : this.getdatosUsuario.id_plantel,

				}

				this.$http.post('reporte.bajaalumno', payload ).then( response => {
          this.validarSuccess( response.data.message )
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },
    },
  }
</script>