<template>
	<v-dialog
    v-model="vermovimiento.estatus"
    persistent
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Pago duplicado</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-data-table
					    :headers="headers"
					    :items="pagos"
					    class="elevation-0"
					    :mobile-breakpoint="100"
		          dense
		          hide-default-footer
					  >
					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="red" 
					    		x-small
					    		dark
					    		@click="ingreso = item, dialogEliminar = true"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-close</v-icon>
					    	</v-btn>
					    </template>
					  </v-data-table>
		  		</v-col>
		  	</v-row>
			</v-card-text class="mb-6">

			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title class="mt-12">
				<span class="text-body-1"><b>Pago Oficial</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-row justify="end">
				  <v-col cols="12">
				  	<v-data-table
					    :headers="headers"
					    :items="pagosOficiales"
					    class="elevation-0"
					    :mobile-breakpoint="100"
		          dense
		          hide-default-footer
					  >
					  </v-data-table>
		  		</v-col>
		  	</v-row>
			</v-card-text>
	    <v-card-actions>
	      <v-btn
	        @click="vermovimiento.estatus = false"
	        rounded
	        color="red"
	        dark
	        class="text-capitalize"
	      >
	        Cancelar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="cerrar()"
	        rounded
	        color="green"
	        dark
	        class="text-capitalize"
	      >
	        Listo
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- CONFIRMAR ELIMINICACIÓN DE DATOS -->
		<v-dialog v-model="dialogEliminar" max-width="600px">
      <v-card>
        <v-card-title class="text-body-1">¿Confirmas que deseas eliminar defiminitvamente este ingreso?</v-card-title>
        <v-card-actions>
          <v-btn 
          	color="red" 
          	dark
          	small
          	rounded
          	@click="dialogEliminar = false, id_ingreso = 0"
          >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
          	color="primary" 
          	dark
          	small
          	rounded
          	@click="eliminarPagoDuplicado"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'vermovimiento',
			'movimiento'
		],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      pagos:[],
      pagosOficiales:[],
      // tabla
      headers: [
        { text: 'id_ingreso'    , value: 'id_ingreso'      },
        { text: 'Alumno'        , value: 'alumno'          },
        { text: 'Grupo'         , value: 'grupo'           },
        { text: 'Plantel'       , value: 'plantel'         },
        { text: 'Monto pagado'  , value: 'monto_pagado'    },
        { text: 'Fecha'         , value: 'fecha_pago'      },
        { text: 'Forma pago'    , value: 'forma_pago'      },
        { text: 'Registró'      , value: 'nombre_completo' },
        { text: 'Actions'       , value: 'actions'         },
      ],

      dialogEliminar: false,
			ingreso: null
    }),

    async created () {
    	console.log( this.movimiento )
    	if( this.movimiento.estatus == 0 ){
	    	this.pagos = []
	    	this.pagos.push( this.movimiento )
    	}
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },


    methods: {
      cerrar( ){
      	this.$emit("initialize")
      	this.vermovimiento.estatus = false
      },

      eliminarPagoDuplicado( ){
      	this.cargar = true
      	this.ingreso['id_autoriza'] = this.getdatosUsuario.iderp
        return this.$http.post('ingreso.eliminar', this.ingreso ).then(response=>{
        	this.cargar         = false
        	this.pagos          = []
        	this.dialogEliminar = false
        	this.validarSuccess( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }
    },
  }
</script>