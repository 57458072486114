<template>
	<v-dialog
    v-model="verajustemetodo.estatus"
    persistent
    width="400"
  >
		<v-card>

			<!-- PAGO DUPLICADO -->
			<v-card-title primary-title>
				<span class="text-body-1"><b>Error en método de pago</b></span>
				<v-spacer></v-spacer>
			</v-card-title>

			<v-card-title primary-title>
				<span class="text-body-1"><b>Nuevo método</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-select
				  :items="tipos_pago"
				  v-model="movimiento.nuevo_tipo_pago"
				  label="Forma de pago"
				  filled
				  dense
          single-line
				  item-text="tipo"
				  item-value="id"
				  hide-details
				  readonly
				></v-select>
			</v-card-text>

			<v-divider></v-divider>

			<!-- PAGO OFICIAL -->
			<v-card-title>
				<span class="text-body-1"><b>Método original</b></span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
		  	<v-select
				  :items="tipos_pago"
				  v-model="movimiento.id_forma_pago"
				  label="Forma de pago"
				  filled
				  dense
          single-line
				  item-text="tipo"
				  item-value="id"
				  hide-details
				></v-select>
			</v-card-text>

			<v-card-actions class="mb-2">
	      <v-btn color="primary" block class="text-capitalize" @click="efectuarCambio">Efectuar cambio de pago</v-btn>
	    </v-card-actions>
			
	    <v-card-actions>
	      <v-btn
	        @click="verajustemetodo.estatus = false"
	        rounded
	        color="red"
	        dark
	        class="text-capitalize"
	      >
	        Cancelar
	      </v-btn>
	      <v-spacer></v-spacer>
	      <v-btn
	        @click="cerrar()"
	        rounded
	        color="green"
	        dark
	        class="text-capitalize"
	      >
	        Ok
	      </v-btn>
	    </v-card-actions>
		</v-card>

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'verajustemetodo',
			'movimiento'
		],

    data: () => ({
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      pagos:[],
      pagosOficiales:[],

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],
     
    }),

    async created () {
    	if( this.movimiento.estatus == 0 ){
	    	this.pagos = []
	    	this.pagos.push( this.movimiento )
    	}
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },
    },

    watch: {
      
      archivos( val ){
        this.total_pagado       = 0
        this.isPracticaja       = false
        this.isTransferencia    = false
        this.isSeven            = false
        this.isOXXO             = false
        this.isWeb              = false
        this.isTarjeta          = false
        this.isDirectoBancoBBVA = false
        this.isDirectoBANREGIO  = false
        if( !val ){ this.vistaPrevia = null } 
      },

      tipo_pago( val ){
        this.total_pagado = 0
      }
    },


    methods: {
      cerrar( ){
      	this.$emit("initialize")
      	this.verajustemetodo.estatus = false
      },

      efectuarCambio( ){
      	const payload = {
	      	id_autoriza        : this.getdatosUsuario.iderp,
	      	id_forma_pago      : this.movimiento.nuevo_tipo_pago,
	      	id_ingreso         : this.movimiento.id_ingreso,
	      	idingresos_reporte : this.movimiento.idingresos_reporte
      	}
      	this.cargar = true
        return this.$http.post('ingreso.update.metodo', payload ).then(response=>{
        	this.cargar         = false
        	this.validarSuccess( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      grabarImagen( ){
        return new Promise((resolve,reject)=>{
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo
          formData.append("file", this.file);

          this.$http.post("grabar.comprobante", formData).then( response => {
            resolve( response.data ) 
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },
    },
  }
</script>